<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Resultado</label>
            <vs-input
              v-model="typeform.result"
              placeholder="Nombre"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Titulo</label>
            <vs-input
              v-model="typeform.title"
              placeholder="Nombre"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Descripcion</label>
            <vs-input
              v-model="typeform.info"
              placeholder="Nombre"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button :loading="loading">Guardar</vs-button>
          <vs-button @click="closeModal" success type="button">
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  emits: ['closeModa'],
  props: {
    typeform: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      success: 'providerErrors/getSuccess',
      title: 'providerErrors/getAlertMessage',
    }),
  },
  methods: {
    async save() {
      try {
        this.loading = true;

        if (this.typeform._id) await this.update(this.typeform);
        else await this.create(this.typeform);

        if (!this.success) throw new Error('ERROR');

        Swal.fire({
          title: this.title,
          icon: 'success',
          timer: 3000,
        });
        this.loading = false;

        this.$emit('closeModa');
      } catch (error) {
        console.log('ERROR SAVE WHEEL', error);
        this.loading = false;
        Swal.fire({
          title: this.title,
          icon: 'error',
          timer: 3000,
        });
      }
    },
    closeModal() {
      this.$emit('closeModa');
    },
    ...mapActions({
      create: 'providerErrors/create',
      update: 'providerErrors/update',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
