import { render, staticRenderFns } from "./AddIncidentRound.vue?vue&type=template&id=b4a1b30a&scoped=true"
import script from "./AddIncidentRound.vue?vue&type=script&lang=js"
export * from "./AddIncidentRound.vue?vue&type=script&lang=js"
import style0 from "./AddIncidentRound.vue?vue&type=style&index=0&id=b4a1b30a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a1b30a",
  null
  
)

export default component.exports