<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> AÑADIR INCIDENCIA</template>
    <div>
      <p>TITULO: {{ incidence.title || '' }}</p>
      <p>DESCRIPCION: {{ incidence.title || '' }}</p>
      <b-row>
        <b-col sm="12" md="6">
          <div>
            <label> {{ $t('filter.roulette') }} </label>
            <div>
              <select
                v-model="typeform.game"
                name="roulette"
                class="form-select border-0 shadow-lg"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in games"
                  :key="index"
                  :value="item._id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col
          sm="12"
          md="6"
          class="d-flex align-items-end justify-content-end"
        >
          <div>
            <vs-button @click="searchRound" :loading="loading"
              >BUSCAR RONDA</vs-button
            >
          </div>
        </b-col>
      </b-row>
      <b-table
        v-if="rounds.length"
        :fields="fields"
        :items="rounds"
        :busy="isBusy"
        responsive
      >
        <template #cell(start_date)="data">
          {{ new Date(data.item.start_date).toLocaleString() }}
        </template>
        <template #cell(end_date)="data">
          {{ new Date(data.item.end_date).toLocaleString() }}
        </template>
        <template #cell(closeRound)="data">
          <vs-button @click="close(data.item)">CERRAR RONDA</vs-button>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { GamesTypes } from '../../../../state/helpers/gamesTypes';
import Swal from 'sweetalert2';

export default {
  props: {
    incidence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      typeform: {
        game: null,
      },
      loading: false,
      fields: [
        {
          key: 'identifierNumber',
          label: 'ID',
          class: 'space-nowrap',
        },
        {
          key: 'providerId',
          label: 'PROVIDER ID',
          class: 'space-nowrap',
        },
        {
          key: 'result',
          label: 'RESULTADO',
          class: 'space-nowrap',
        },
        {
          key: 'start_date',
          label: 'INICIO',
          class: 'space-nowrap',
        },
        {
          key: 'end_date',
          label: 'FIN',
          class: 'space-nowrap',
        },
        {
          key: 'closeRound',
          label: 'ENVIAR INCIDENCIA',
          class: 'space-nowrap',
        },
      ],
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      games: 'games/getGames',
      rounds: 'reports/getRounds',
      providerRounds: 'reports/getProviderLogs',
      success: 'round/isSuccess',
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    async searchRound() {
      this.loading = true;

      const filterData = {
        roulette: this.typeform.game,
      };

      await this.fetchRounds({
        gameType: GamesTypes.Roulette,
        filterData,
        page: 1,
        limit: 1,
      });

      this.loading = false;
    },
    async close(item) {
      console.log('item', item);
      if (item.result !== -1)
        return Swal.fire('Error', 'La ronda ya fue cerrada', 'error');

      this.isBusy = true;

      await this.fetchProviderLogs({
        round: item.identifierNumber,
        page: 1,
        limit: 1,
      });

      if (!this.providerRounds.length) {
        this.isBusy = false;
        return Swal.fire('Error', 'No se encontraron registros', 'error');
      }

      const providerId = this.games.find(
        (game) => game._id === this.typeform.game
      ).providerId;

      if (!providerId) {
        this.isBusy = false;

        return Swal.fire('Error', 'No se encontró el proveedor', 'error');
      }

      const data = {
        ID_Ronda: this.providerRounds[0].id_round,
        ID_Ruleta: providerId,
        Resultado: Number(this.incidence.result),
      };
      console.log('data', data);

      await this.closeRound(data);

      if (!this.success) {
        this.isBusy = false;

        return Swal.fire('Error', 'No se pudo cerrar la ronda', 'error');
      }

      Swal.fire('Exito', 'Ronda cerrada con exito', 'success');
      this.isBusy = false;

      const { isConfirmed } = await Swal.fire({
        title: 'Desea crear otra ronda?',
        icon: 'question',
        showCancelButton: true,
      });

      if (!isConfirmed) return;

      await this.createRound(providerId);
    },
    async createRound(ID_Ruleta) {
      try {
        const { status } = await this.$http.put('round/start', {
          ID_Ruleta: String(ID_Ruleta),
          ID_Ronda: crypto.randomUUID(),
          Fecha: new Date().toString(),
          Giro: '0',
          Rpm: '22',
          Error: '0',
          Resultado: '99',
        });

        if (status > 201) {
          throw ('ERROR CREATE ROUND', status);
        }

        Swal.fire('Exito', 'Ronda creada con exito', 'success');
      } catch (error) {
        console.log('ERROR CREATE ROUND', error);
        Swal.fire('Error', 'No se pudo crear la ronda', 'error');
      }
    },
    ...mapMutations({
      setRounds: 'reports/setRounds',
    }),
    ...mapActions({
      fetchGames: 'games/fetchGames',
      fetchRounds: 'reports/fetchRounds',
      fetchProviderLogs: 'reports/fetchProviderLogs',
      closeRound: 'round/closeRound',
    }),
  },
  async mounted() {
    await this.fetchGames({ gameType: GamesTypes.Roulette });
  },
  beforeDestroy() {
    this.setRounds([]);
  },
};
</script>

<style scoped>
.space-nowrap {
  white-space: nowrap !important;
}
</style>
